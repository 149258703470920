import React, { useEffect, useState } from "react";
import logo from "./media/images/logo.png";

const App = () => {
	const [error, setError] = useState<any | null>(null);
	const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
	const [audioNumber, setAudioNumber] = useState<number>(0);

	const handleAudio = () => {
		try {
			if (audio) return;
			const randomNumber = Math.floor(Math.random() * 6);

			import(`./media/audio/${randomNumber}.webm`).then(audio => {
				setAudio(new Audio(audio.default));
				setAudioNumber(randomNumber);
			});
		} catch (error: any) {
			setError(error);
		}
	};

	useEffect(() => {
		if (!audio) return;

		audio.volume = audioNumber === 5 ? 1 : 0.2;
		audio.play();

		audio.addEventListener("ended", () => setAudio(null));
	}, [audio, audioNumber]);

	return (
		<div
			className={
				"container flex flex-col justify-center items-center min-h-screen gap-5"
			}
		>
			<header>
				<img
					src={logo}
					className={"max-w-[460px] w-full animate-spin"}
					alt={"project animation logo"}
				/>
			</header>

			<main className={"flex flex-col justify-center items-center text-center"}>
				<h1 className={"text-4xl font-black"}>SCP:DC Community</h1>
				<p className={"text-lg w-2/3"}>
					This website in developing. Something will be here soon! ;)
				</p>
				{error && (
					<p className={"text-red-500"}>Hm, music did not start, try again</p>
				)}
				{audioNumber === 5 && (
					<p className={"text-red-500"}>AAAAAA!!!! Scared?</p>
				)}
				<button
					className={
						"bg-gray-100 text-black px-2 py-1 mt-2 transition-colors border border-gray-900/40 rounded-sm hover:bg-gray-200/95"
					}
					onClick={() => handleAudio()}
					disabled={!!audio}
				>
					How about music? 🎵
				</button>
			</main>
		</div>
	);
};

export default App;
